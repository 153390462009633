import { mediaBreakpointMixin, spaceMixin } from '@zillow/constellation';
import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
    display: flex;
    justify-content: center;
    > main {
        max-width: 1280px;
        width: 100%;
        margin: auto;
        padding-left: 0;
        padding-right: 0;
        max-width: 100vw;
        @media ${mediaBreakpointMixin('lg')} {
            padding-left: ${spaceMixin('md')};
            padding-right: ${spaceMixin('md')};
        }

        @media ${mediaBreakpointMixin('xl')} {
            padding-left: 64px;
            padding-right: 64px;
        }
    }
`;
const StyledMain = styled.main``;

interface Props {
    children: React.ReactNode;
}

const Container: React.FC<Props> = ({ children }) => (
    <StyledContainer>
        <StyledMain>{children}</StyledMain>
    </StyledContainer>
);

export default Container;
