import React from 'react';
import type { AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';
import { ThemeConstellation, ToastProvider } from '@zillow/constellation';
import Container from '../layouts/Container';

if (typeof window !== 'undefined') {
    // @ts-expect-error: This is the recommended option to disabling ZG analytics in the browser
    window['disable-analytics'] = true;
}

// eslint-disable-next-line zillow/@typescript-eslint/naming-convention
const MyApp = ({ Component, pageProps }: AppProps) => (
    <ThemeProvider theme={ThemeConstellation}>
        <ToastProvider placement="bottom">
            <Container>
                <Component {...pageProps} />
            </Container>
        </ToastProvider>
    </ThemeProvider>
);

export default MyApp;
